import neomediaLogo from "../assets/images/neomedia.png";
const AWS_BUCKET = 'https://sources.megaplayer.me';

const NEOMEDIA = {
    logo: neomediaLogo,
    name: 'Neomedia',
    favicon: AWS_BUCKET+'/favicon/neomedia.ico',
    web: 'www.neomediadigital.com',
    supportContact: 'info@neomediadigital.com',
    url_downloads: null,
    url_web_player_p: 'https://webplayer.neomediadigital.com',
    url_web_player_t: 'https://webplayertest.megaplayer.me/session/signIn/7',
    url_install_win: AWS_BUCKET+'/installers/install_neomedia_',
    url_install_macos: null,
    url_google_play_store: 'https://play.google.com/store/apps/details?id=com.neomedia.neomedia',
    url_aptoide: null,
    url_apk_x32: AWS_BUCKET+'/installers/app_neomedia_x32.apk',
    url_apk_x64: AWS_BUCKET+'/installers/app_neomedia_x64.apk',
    url_support_i: 'https://www.neomediadigital.com/',
    url_support_m: 'https://www.neomediadigital.com/',
    url_whatsapp: null,
    url_certificate: AWS_BUCKET+'/ks_certificate/neomedia_certificado_freedom.pdf'
}

export default NEOMEDIA;